import React, { useState } from 'react';
import { Grid, Typography, Button, Box, Popover, IconButton, Drawer, List, ListItem, ListItemText, Divider, Collapse } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import GetMyProperty from '../../assets/logo1.png';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { ArrowDropDown, } from '@mui/icons-material';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import HomeIcon from '@mui/icons-material/Home';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import GroupIcon from '@mui/icons-material/Group';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import FeedIcon from '@mui/icons-material/Feed';
import ContactsIcon from '@mui/icons-material/Contacts';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CollectionsIcon from '@mui/icons-material/Collections';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Header1 = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseEnter = () => {
        setAnchorEl(anchorEl); // keep popover open on hover
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const handleMouseOver = () => {

    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const open = Boolean(anchorEl);


    const [ope, setOpe] = useState(false);

    const handleClick = () => {
        setOpe(!ope);
    };

    return (
        <Grid container spacing={2} mb={-3} >
            <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'space-between' : 'flex-start' }}>
                {isMobile && (
                    <Grid sx={{ mb: { md: 5, xs: 0 } , cursor: "pointer",ml:3,mt:2}}>
                        <img
                            src={GetMyProperty}
                            alt="GetMyProperty"
                            width={90}
                            onClick={() => navigate('/')}
                        />
                    </Grid>
                )}
                 {!isMobile && (
                    <Grid container 
                     sx={{ mb: { md: 5, xs: 0 }, cursor: "pointer", mt:1,justifyContent:'center' }}>
                        <img
                            src={GetMyProperty}
                            alt="GetMyProperty"
                            width={160}
                            onClick={() => navigate('/')}
                            
                        />
                    </Grid>
                )}

                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ mr: 3, mt: 1 }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
            </Grid>

            <Grid item xs={12} md={10} sx={{ mb: 2 }}>
                <Grid container sx={{ flexDirection: 'column', gap: '20px' }}>
                    {!isMobile && (
                        <Grid item sx={{ justifyContent: 'flex-end', borderRadius: '8px', ml: '2' }}>
                            <Grid container sx={{ flexDirection: 'row', backgroundColor: '#004e6a', color: 'white', height: '50px', borderBottomLeftRadius: '50px', textAlign: 'center' }}>
                                <Grid display={'flex'} sx={{ mt: '15px' }}>
                                    <PhoneIcon sx={{ ml: 8 }} />
                                    <Typography variant="body1" ml={1} sx={{ fontSize: 16, fontWeight: 400 }}>
                                        <a href="tel:+919740857368" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            (+91) 9740857368
                                        </a>
                                    </Typography>
                                </Grid>
                                <Grid display={"flex"} sx={{ mt: '15px' }}>
                                    <EmailIcon sx={{ ml: 2 }} />
                                    <Typography variant="body1" ml={1} sx={{ fontSize: 16, fontWeight: 400 }}>
                                        info@getmoredigital.com
                                    </Typography>
                                </Grid>
                                {!isMobile && (
                                    <Box sx={{ display: 'flex', position: 'absolute', right: '0', mt: '10px', mb: 1, gap: '5px', mr: 1, }}>
                                        <Button
                                            sx={{
                                                backgroundColor: "white",
                                                color: 'black',
                                                borderRadius: '10px',
                                                fontSize: { xs: '0.7rem', sm: '0.875rem' },
                                                padding: { xs: '10px 10px', sm: '8px 16px' },
                                                '&:hover': { backgroundColor: 'blue', color: 'white' },
                                                height: 30
                                            }}
                                            onClick={() => navigate('/login')}
                                        > Sign-In</Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "white",
                                                color: 'black',
                                                borderRadius: '10px',
                                                fontSize: { xs: '0.7rem', sm: '0.875rem' },
                                                padding: { xs: '10px 10px', sm: '8px 16px' },
                                                '&:hover': { backgroundColor: 'blue', color: 'white' },
                                                height: 30
                                            }}
                                        >
                                            Sign-Up
                                        </Button>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {/* Drawer for Mobile */}
                    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                        <Box
                            sx={{
                                width: 250,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Grid sx={{ mb: { md: 5, xs: 1 } , cursor: "pointer",ml:3,mt:2 , justifyContent: 'space-between',}}>
                        <img
                            src={GetMyProperty}
                            alt="GetMyProperty"
                            width={60}
                            onClick={() => navigate('/')}
                        />
                    
                                {/* <IconButton onClick={toggleDrawer(false)} sx={{ml:'auto'}}>
                                    <CloseIcon />
                                </IconButton> */}
                          </Grid>
                            <Divider />
                            <List >
                                <ListItem button onClick={() => navigate('/')}>
                                    <HomeIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Home" />
                                </ListItem>

                                <ListItem button onClick={handleClick}>
                                    <ModelTrainingIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Training" />
                                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                                </ListItem>
                                <Collapse in={ope} timeout="auto" unmountOnExit sx={{ backgroundColor: '#f7f7f2' }}>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => navigate('/digital-marketing')}>
                                            <ListItemText primary="Digital Marketing" />

                                        </ListItem>
                                        <Divider />
                                        <ListItem button onClick={() => navigate('/software-development')}>
                                            <ListItemText primary="Software Development" />
                                        </ListItem>
                                        <Divider />
                                        {/* <ListItem button onClick={() => navigate('/software-testing')}>
                                            <ListItemText primary="Software Testing" />
                                        </ListItem> */}
                                        {/* <ListItem button onClick={() => navigate('/allgraphic')}>
                                            <ListItemText primary="Graphic Designing" />
                                        </ListItem> */}
                                    </List>
                                </Collapse>

                                <ListItem button onClick={() => navigate('/services-page')}>
                                    <DesignServicesIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Services" />
                                </ListItem>

                                <ListItem button onClick={() => navigate('/allabout')}>
                                    <GroupIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="About Us" />
                                </ListItem>

                                {/* <ListItem button onClick={() => navigate('/allclient')}>
                                    <ConnectWithoutContactIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Clients/Media Dec" />
                                </ListItem> */}

                                <ListItem button onClick={() => navigate('/allblogs')}>
                                    <FeedIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Blog" />
                                </ListItem>

                                <ListItem button onClick={() => navigate('/allcontact')}>
                                    <ContactsIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Contact Us" />
                                </ListItem>
                                <ListItem button onClick={() => navigate('/gallery')}>
                                    <CollectionsIcon sx={{ mr: 2 }} />
                                    <ListItemText primary="Gallery" />
                                </ListItem>



                            </List>
                        </Box>
                    </Drawer>

                    <Grid
                        item
                        container
                        sx={{
                            fontWeight: "bold",
                            alignItems: 'center',
                            padding: '0',
                            mt: -1,
                            mb: isMobile ? -4 : 2,
                            flexWrap: 'wrap', // Allow buttons to wrap
                            justifyContent: 'space-between' // Distribute space evenly
                        }}
                    >
                        {!isMobile && (
                            <>
                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/')}
                                >
                                    Home
                                </Button>

                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/training')}
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    Training
                                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                                </Button>

                                {isTablet && (
                                    <Button
                                        sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                        onClick={handleClick}
                                    >
                                        Training
                                    </Button>
                                )}

                                <Popover
                                    sx={{ pointerEvents: 'auto' }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleMouseLeave}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
                                        <Button
                                            sx={{ p: 2, color: '#402f2e', justifyContent: 'flex-start', margin: '0 0 0.5rem' }}
                                            onClick={() => navigate('/digital-marketing')}
                                        >
                                            Digital Marketing
                                        </Button>
                                        <Divider />
                                        <Button
                                            sx={{ p: 2, color: '#402f2e', justifyContent: 'flex-start', margin: '0 0 0.5rem' }}
                                            onClick={() => navigate('/software-development')}
                                        >
                                            Software Development
                                        </Button>
                                        <Divider />
                                        <Button
                                            sx={{ p: 2, color: '#402f2e', justifyContent: 'flex-start', margin: '0' }}
                                            onClick={() => navigate('/allgraphic')}
                                        >
                                            Graphic Designing
                                        </Button>
                                    </Box>
                                </Popover>

                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/services-page')}
                                >
                                    Services
                                </Button>

                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/allabout')}
                                >
                                    About Us
                                </Button>

                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/allblogs')}
                                >
                                    Blog
                                </Button>

                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/allcontact')}
                                >
                                    Contact Us
                                </Button>

                                <Button
                                    sx={{ color: 'black', fontSize: '1rem', margin: '0.5rem' }}
                                    onClick={() => navigate('/gallery')}
                                >
                                    Gallery
                                </Button>

                                <Button
                                    sx={{
                                        color: "#fff",
                                        background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                                        padding: '0.625rem', // Equivalent to 10px
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '0.5rem'
                                    }}
                                >
                                    <PhoneIcon sx={{ marginRight: '0.5rem', color: 'inherit' }} />
                                    <a href="tel:+919740857368" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        +91-9740857368
                                    </a>
                                </Button>
                            </>
                        )}
                    </Grid>


                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header1;