import React from 'react';
import { Typography, Grid, useMediaQuery, Divider } from '@mui/material';
import bimage1 from '../../assets/1.jpg';
import banner2 from '../../assets/4.jpg';
import bimage2 from '../../assets/digitalmarketing1.jpeg';
import bimage3 from '../../assets/5.jpg';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'; 
import styled from 'styled-components';


const Aboutdigital = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:1200px)');

    const { ref: cardOneRef, inView: cardOneInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: cardTwoRef, inView: cardTwoInView } = useInView({ triggerOnce: true, threshold: 0.4 });
    const { ref: cardThreeRef, inView: cardThreeInView } = useInView({ triggerOnce: true, threshold: 0.6 });

    const customerSatisfactionRating = 4.9;
    const newVisitorsRating = 4.5;

    const renderStars = (rating) => {
        const starSize = isMobile ? { fontSize: '0.8rem' } : isTablet ? { fontSize: '0.9rem' } : { fontSize: '1rem' };

        return [...Array(5)].map((_, index) => {
            if (index < Math.floor(rating)) {
                return <StarIcon key={index} color='warning' style={starSize} />;
            }
            if (index === Math.floor(rating) && rating % 1 !== 0) {
                return <StarIcon key={index} color='warning' style={{ ...starSize, opacity: 0.5 }} />;
            }
            return <StarBorderIcon key={index} color='warning' style={starSize} />;
        });
    };

    const StyledSpan = styled.span`
    position: relative;
    // color: purple;
    padding: 0.5em 1em; /* Adjust padding to create space around text */
    border: 2px solid purple; /* Circular outline */
    border-radius: 50%; /* Makes the border circular */
    display: inline-block; /* Ensure the span can contain the circle */
  
    /* Centering the text inside the circle */
    line-height: 1; /* Adjusts line height for better vertical alignment */
    text-align: center; /* Centers text inside the span */
  `;
    return (
        <Grid container sx={{ direction: 'flex', px: 5 }} mt={2} mb={2} >
            <Grid item xs={12} md={5} sx={{ mt:{xs:0, sm:'-8%',md:3 }}}>
                <Grid container sx={{ flexDirection: isMobile ? 'column' : 'row' }} spacing={2}>
                    <Grid item xs={12} md={6} sx={{ mt: isMobile ? 1 : 15,  } }>
                        <motion.div
                            ref={cardOneRef}
                            initial={{ opacity: 0, y: 50 }}
                            animate={cardOneInView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 1, delay: 0 }}
                        >
                            <img
                                src={banner2}
                                alt='banner2'
                                style={{ width: '100%', height: isMobile ? 'auto' : '100%', borderRadius: '25px' }}
                            />
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container sx={{ flexDirection: 'column' }} spacing={2}>
                            <Grid item>
                                <motion.div
                                    ref={cardTwoRef}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={cardTwoInView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 1, delay: 0 }}
                                >
                                    <img
                                        src={bimage1}
                                        alt='bimage1'
                                        style={{ width: '100%', height: isMobile ? 'auto' : '100%', borderRadius: '25px' }}
                                    />
                                </motion.div>
                            </Grid>
                            <Grid item>
                                <motion.div
                                    ref={cardThreeRef}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={cardThreeInView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 1, delay: 0 }}
                                >
                                    <img
                                        src={bimage2}
                                        alt='bimage2'
                                        style={{ width: '100%', height: isMobile ? 'auto' : '100%', borderRadius: '25px' }}
                                    />
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={7} sx={{mt:{xs:1,md:8, sm:18 , } }} >
                <Typography fontFamily='initial' fontWeight='bold' sx={{ fontSize: {xs:20, md: 20, sm:25} ,ml:{xs:0, md:10, sm:0 }}}color='#2a4bb8' >
                    About Us
                </Typography>
                <Typography variant='h2' fontFamily='initial' sx={{ fontSize: {xs:30, md: '30px', sm:35}, ml:{xs:0, md:10, sm:0 } }} color='#4e8c96' fontWeight={600}>
                  GetMoreDigital
                </Typography>
                <Grid   sx={{ justifyContent: 'left',ml:{xs:0, md:0, sm:0 } }}>
                    <Typography variant="body1" sx={{ marginTop: '15px',  textAlign: 'justify', justifyContent: 'left',ml:{xs:0, md:10, sm:0 }  }}>
                        GetMoreDigital is a service that supports companies in enhancing their online presence by providing customized digital marketing solutions.
                        We offer a range of services including content creation, social media management, SEO, and performance analytics.
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' ,ml:{xs:0, md:10, sm:0 }}}>
                        Our team of experts creates customized <StyledSpan> Training programs</StyledSpan> to improve learning outcomes and student engagement, with an eye toward optimizing return on investment and fitting in with your academic objectives.
                    </Typography>
                </Grid>

                <Grid container sx={{ flexDirection: isMobile ? 'row' : 'row' ,ml:{xs:0, md:10, sm:0 } ,mb:{xs:3, md:'none', sm:3}}} >
                    <Grid item mt={5} sx={{ml:{xs:0, md:1, sm:0 }}}>
                        <Typography fontWeight={'bold'} sx={{fontSize:{xs:15, sm:30, md:25}}}>99%</Typography>
                        <Typography fontWeight={'bold'} sx={{fontSize:{xs:10, sm:20, md:20}}}> Satisfied Students</Typography>
                        <Grid container alignItems="center" mt={1}>
                            {renderStars(customerSatisfactionRating)}
                        </Grid>
                    </Grid>
                    <Grid item mt={5} sx={{ml:{xs:3, md:10, sm:10 }}}>
                        <Typography fontWeight={'bold'} sx={{fontSize:{xs:15, sm:30, md:25}}}>3x</Typography>
                        <Typography fontWeight={'bold'} sx={{fontSize:{xs:10, sm:20, md:20}}}> New Visitors </Typography>
                        <Grid container alignItems="center" mt={1}>
                            {renderStars(newVisitorsRating)}
                        </Grid>
                    </Grid>
                    <Grid item mt={ 5} sx={{ml:{xs:3, md:10, sm:10 }}}>
                        <Typography fontWeight={'bold'} sx={{fontSize:{xs:15, sm:30, md:25}}}>20+</Typography>
                        <Typography fontWeight={'bold'} sx={{fontSize:{xs:10, sm:20, md:20}}}> Skilled Experts </Typography>
                        <Grid container alignItems="center" mt={1}>
                            {renderStars(newVisitorsRating)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Aboutdigital;
