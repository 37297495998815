import React from 'react';
import { Typography, Box, Button, useMediaQuery, useTheme,  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Servicebanner2 from '../../assets/13a.jpg';
import Servicebanner1 from "../../assets/12a.jpg";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import banner11 from '../../assets/banner22.jpg';
import { Grid } from '@mui/joy';

const slidesData = [
  {
    title: "Digital Marketing Courses - Strategy and Resources",
    description: "Unlock the full potential of digital marketing power with comprehensive training that equips you to thrive online.",
    image: Servicebanner1,
    buttonText: "Explore More",path: '/digital-marketing',
  },
  {
    title: "Transformative Ideas for Growth and Success",
    description: "Optimize your company's growth and success by transforming it with creative ideas and professional advice.",
    image: Servicebanner2,
    buttonText: "Discover More",path: '/services-page',
  }
];

const Arrow = ({ className, style, onClick, direction }) => {

  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Box
      className={className}
      sx={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20%',
        cursor: 'pointer',
        zIndex: 1,
        width: '40px',
        height: '40px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        [direction === 'left' ? 'left' : 'right']: isMobile ? '20px' : '70px',
      }}
      onClick={onClick}
    >
      {direction === 'left' ? <ArrowCircleLeftRoundedIcon /> : <ArrowCircleRightRoundedIcon />}
    </Box>
  );
};

const Banner = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <Arrow direction="right" />, 
    prevArrow: <Arrow direction="left" />,
    customPaging: (i) => (
      <Box
        sx={{
          
        }}
      />
    ),
  };

  return (
    <Box
      sx={{
       pl:{md:2,xs:2},
        backgroundImage: `url(${banner11})`,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height:'550px'
      }}
    >
      
      <Slider {...sliderSettings}>
        {slidesData.map((slide, index) => (
          <Box key={index}>
            <Box
              sx={{
                display: 'flex',
              mt:5,
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isTablet ? '30px' : (isMobile ? '40px' : '60px'),
              }}
            >
              <Box sx={{ width: isMobile ? '100%' : (isTablet ? '50%' : '40%'), textAlign: {md:'left',xs:"center"}, padding: '10px' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: 2,
                    marginRight: isMobile ? '20px' : 0,
                    color: 'white',
                    fontSize: isMobile ? '22px' : (isTablet ? '28px' : '30px'),
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 3,
                    color: 'white',
                    fontSize: isMobile ? '16px' : (isTablet ? '18px' : '18px'),  // Adjust font size for tablet
                  }}
                >
                  {slide.description}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '40px',
                    backgroundColor: "linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)",
                    color: 'white',
                    padding: isMobile ? '8px 20px' : (isTablet ? '10px 25px' : '10px 30px'),  // Adjust button padding for tablet
                    mb: isMobile ? '30px' : '0',
                    
                  }}
                  onClick={() => navigate(slide.path)}
                >
                  {slide.buttonText}
                </Button>
              </Box>

              <Box sx={{ width: isMobile ? '100%' : (isTablet ? '50%' : '40%'), textAlign: isMobile ? 'center' : 'right', padding: '10px' }}>
                <img
                  src={slide.image}
                  alt={slide.title}
                  style={{ width: '90%', height: isMobile ? '200px' : (isTablet ? '250px' : '300px'), borderRadius: '20px' }}  // Adjust image height for tablet
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
   
    </Box>
  );
};

export default Banner;
