import React, { useRef, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Divider, Grid, IconButton, Button, CardMedia, useMediaQuery } from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import python from '../../../../assets/topics/python.png';
import fullstack from '../../../../assets/topics/fullstack.png'
import react from '../../../../assets/topics/react.png';
import mern from '../../../../assets/topics/mern1.png'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import data from '../../../../assets/topics/data.png';
import back from '../../../../assets/topics/backend.png';
import front from '../../../../assets/topics/frontend.png';
import aiml from '../../../../assets/topics/ai.png';
import javacode from '../../../../assets/topics/javacode.png'
import js from '../../../../assets/topics/js.png';
import db from '../../../../assets/topics/database.png';
import reactnative from '../../../../assets/topics/reactnative.png';
import aiai from '../../../../assets/topics/aiai.png';
import kotlin from '../../../../assets/topics/kotlin.png';
import ruby from '../../../../assets/topics/ruby.png';
import dart from '../../../../assets/topics/dart.png';
import golang from '../../../../assets/topics/go.png';
import banner1 from '../../../../assets/topics/data.png';
import banner from '../../../../assets/12.jpg';

const Ripple = styled('span')(({ delay, isDesktop }) => ({
    position: 'absolute',
    borderRadius: '50%',
    width: isDesktop ? '100px' : '60px', 
    height: isDesktop ? '100px' : '60px', 
    backgroundColor: 'rgba(104, 77, 244, 0.2)',
    transform: 'translate(-50%, -50%)',
    animation: `heroripple 8s ${delay}s linear infinite`,
    '@keyframes heroripple': {
      '0%': {
        transform: 'translate(-50%, -50%) scale(0)',
        opacity: 0.5,
      },
      '100%': {
        transform: 'translate(-50%, -50%) scale(4)',
        opacity: 0,
      },
    },
  }));

const StyledSlider = styled(Slider)({
    '& .slick-prev, & .slick-next': {
        zIndex: 1,
        width: 30,
        height: 30,
        '&:before': {
            fontSize: 30,
            color: 'black',
        },
    },
    '& .slick-prev': {
        left: -40,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    '& .slick-next': {
        right: -30,
        top: '50%',
        transform: 'translateY(-50%)',
    },
});

const Slidering = styled(Slider)({
    '& .slick-prev, & .slick-next': {
        zIndex: 1,
        width: 30,
        height: 30,
        '&:before': {
            fontSize: 30,
            color: '#004e6a',
        },
    },
    '& .slick-prev': {
        left: -40,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    '& .slick-next': {
        right: -40,
        top: '50%',
        transform: 'translateY(-50%)',
    },
});
const RoboSlider = styled(Slider)({
    '& .slick-prev, & .slick-next': {
        zIndex: 1,
        width: 30,
        height: 30,
        '&:before': {
            fontSize: 30,
            color: 'white',
        },
    },
    '& .slick-prev': {
        left: -40,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    '& .slick-next': {
        right: -50,
        top: '50%',
        transform: 'translateY(-50%)',
    },
});
const Comp2 = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const navigate = useNavigate();

    // Define the scrollToSection function
    const handleRadioChange = (event) => {
        const selectedId = event.target.value;  // Get the selected radio button value
        scrollToSection(selectedId);            // Scroll to the corresponding section
    };


    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start',  // Scroll to the top of the section
            });
        }
    };


    const handelClick = () => {
        navigate('/');
        window.scrollTo(0, 0);
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    const skillsettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 2,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const robosettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,

                },
            },
        ],
    };

    const visionMissionData = [
        {
            image: python,
            title: 'Python',
            duration: 'Duration: 3-6 months',
            rating: 4.5,
            reviewCount: 130,
        },
        {
            image: fullstack,
            title: 'Full-Stack ',
            duration: 'Duration: 3-6 months',
            rating: 4.5,
            reviewCount: 130,
        },
        {
            image: front,
            title: 'Front-End',
            duration: 'Duration: 3-6 months',
            rating: 4.5,
            reviewCount: 130,
        },
        {
            image: back,
            title: 'Back-End',
            duration: 'Duration: 3-6 months',
            rating: 4.5,
            reviewCount: 130,
        },
        {
            image: mern,
            title: 'MERN Stack ',
            duration: 'Duration: 3-6 months',
            rating: 4.5,
            reviewCount: 130,
        },
        {
            image: aiml,
            title: 'AI & ML ',
            duration: 'Duration: 4-6 months',
            rating: 4.5,
            reviewCount: 130,
        },
    ];
    const robot = [
        {

            title: 'ROS',
            description: 'Learn libraries and tools for building robot applications. ',

        },
        {

            title: 'TensorFlow',
            description: 'learn to apply AI in creating dynamic, data-informed marketing strategies.',

        },
        {

            title: 'OpenCV',
            description: 'Offers advanced video analytics for optimizing video content strategies.',

        },
        {

            title: 'MoveIt',
            description: 'ideal for advanced robotic arm manipulation and motion control.',

        },


    ];

    const renderStars = (rating) => {
        const starSize = { fontSize: '1rem' };

        return [...Array(5)].map((_, index) => {
            if (index < Math.floor(rating)) {
                return <StarIcon key={index} color="warning" style={starSize} />;
            }
            if (index === Math.floor(rating) && rating % 1 !== 0) {
                return <StarIcon key={index} color="warning" style={{ ...starSize, opacity: 0.5 }} />;
            }
            return <StarBorderIcon key={index} color="warning" style={starSize} />;
        });
    };
    const post = [
        { title: 'Microsofware ' },
        { title: 'Google Cloud' },
        { title: 'PMI Certification' },
        { title: 'AWS Certification' },
        { title: 'Cyber Security' },
        { title: ' ISACA Certification' },
        { title: 'ISC2 Certification' },
        { title: 'Cloud Computing' },
        // { title: 'Angular JS' },
        // { title: 'Machine Learning' },
        // { title: 'Ruby' },
    ];
    const ripples = [0, 1, 2, 3, 4];
    const isDesktop = useMediaQuery('(min-width:960px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width:959px)');


    const images = [
        {
            image: data,
            title: ' Data Science '
        },
        {
            image: js,
            title: ' Java Script '
        },
        {
            image: javacode,
            title: ' Java  '
        },
        {
            image: db,
            title: ' Database'
        },
        {
            image: reactnative,
            title: ' React Native'
        },
        {
            image: aiai,
            title: ' Artificial Intelligence  '
        },
        {
            image: ruby,
            title: ' Ruby '
        },
        {
            image: dart,
            title: ' Dart   '
        },
        {
            image: golang,
            title: ' Golang   '
        },
        {
            image: kotlin,
            title: ' Kotlin   '
        },
    ];
    const programDetails = [
        { id: 'explore-skills', text: 'Explore Skills' },
        { id: 'vision-mission', text: 'Vision & Mission' },
        { id: 'programs-certificates', text: 'Programs and Certificates' },
        // { id: 'career-growth', text: ' Career and Growth ' },
        { id: 'programming-robotics', text: 'Robotics Frameworks' },

    ];

    return (
        <Box sx={{ flexGrow: 1, padding: '2rem', backgroundColor: '#f2f5f4' }}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={4} mt={3} gap={3}>
                    <Card sx={{ marginBottom: '1rem', width: '80%', height: '90%', borderRadius: '8px', ml: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#004e6a', padding: '0.5rem', borderRadius: '5px' }}>
                                <Typography variant="h2" sx={{ color: 'white', fontWeight: 'bold', fontSize: '30px' }}>
                                    Program Details
                                </Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="program-details"
                                        name="program-details-group"
                                        onChange={handleRadioChange}
                                    >
                                        {programDetails.map((item, index) => (
                                            <FormControlLabel
                                                key={index}
                                                // id={item.id}
                                                value={item.id}  // Ensure this value matches the section `id`
                                                control={<Radio />}
                                                label={item.text}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>

                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={8} p={2} mt={{ xs: 5, md: 2, sm: 5 }} ml={{ xs: 1, md: 0 }} >
                    <Typography variant='h3' id="about-department" sx={{ fontWeight: 600, mb: 1, textAlign: { xs: "left", md: 'left', sm: 'center' },  fontSize: { xs: '30px', sm: '40px', md: '40px' }, color: '#4b87a6' }}>
                        Top Rating Courses
                    </Typography>
                    <Divider sx={{ backgroundColor: 'purple', height: '2px', mb: 3, width: { xs: '300px', md: '380px', sm: '400px' }, ml: { sm: 16, md: 0 } }} />

                    <StyledSlider {...settings}>
                        {visionMissionData.map((post, index) => (
                            <Grid key={index}>
                                <Card sx={{ marginBottom: 2, width: { md: '90%', sm: '90%', xs: '100%' }, borderRadius: 2, ml: { md: 0, xs: 0, sm: 1 } }}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            height: { xs: 200, sm: 180, md: 200 },  // Adjust height based on screen size
                                            width: { xs: 200, sm: 230, md: 200 },                         // Keep the width proportional to height
                                            maxWidth: '100%',
                                            ml: 5                   // Ensure it doesn't overflow
                                        }}
                                        image={post.image}
                                        alt={post.title}
                                    />

                                    <CardContent sx={{ flexGrow: 1, margin: { xs: 0 } }}>
                                        <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 600, height: 80, mb: -4 }}>
                                            {post.title}
                                        </Typography>
                                        <Divider />
                                        <Grid container alignItems="center" mt={1}>
                                            {renderStars(post.rating)}
                                            <Typography variant="body2" sx={{ marginLeft: 0 }}>
                                                {`(${post.reviewCount} reviews)`}
                                            </Typography>
                                            <Button
                                                variant='contained'
                                                sx={{ borderRadius: '30px', mt: 2, padding: '4px 12px', fontSize: '0.85rem', backgroundColor: '#004e6a', ml: 7 }}
                                                onClick={() => navigate()}
                                            >
                                                Know More
                                            </Button>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </StyledSlider>
                </Grid>

                {/* Explore skills */}

                <Divider sx={{ my: 3 }} />
                <Grid container spacing={2} id="explore-skills" >
                    <Grid item xs={12} padding={10} sx={{ mt: { md: 2, sm: -3, xs: 3 }, ml: { md: 5, xs: 5 } }} >
                        <Typography variant="h3" sx={{ fontWeight: 600, mb: 1, textAlign: { md: 'center', xs: 'left', sm: 'center' },  fontSize: { xs: '30px', sm: '40px', md: '40px' }, mt: { md: 'none', sm: 8 }, color: '#4b87a6' }}>
                            Explore Skills
                        </Typography>
                        {/* <Divider sx={{ backgroundColor: 'orange',alignItems:'center', height: '2px', mb: 3, width: { xs: '230px', md: '390px', sm: '200px' } }} /> */}
                        <Slidering {...skillsettings}>
                            {images.map((images, index) => (
                                <Grid key={index} >
                                    <Card sx={{ mt: 2, mb: 2, width: { md: '90%', sm: '85%', xs: '100%' }, mx: 'auto', borderRadius: 2, position: 'relative', }}>
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image={images.image}
                                            alt={images.title}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',

                                                backgroundColor: 'rgba(0.3, 0, 0, 0.3)', // Add a semi-transparent overlay
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                textAlign: 'start',
                                            }}
                                        >
                                            <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: 30, mt: 15 }}>
                                                {images.title}
                                            </Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Slidering>
                    </Grid>
                </Grid>

            </Grid>

            {/* Vision mission Card */}
            <Grid container spacing={2} direction="row" id='vision-mission' sx={{ padding: { md: '0px 100px', xs: '0px 100px' } }} gap={6} mb={8}>
                <Grid item xs={12}>
                    <Typography variant='h3' sx={{ textAlign: { md: 'center', xs: 'left' }, fontWeight: 600, color: '#4b87a6',  fontSize: { xs: '30px', sm: '40px', md: '40px' }, padding: { md: 'none' } }}> Vision and Mission</Typography>
                </Grid>
                <Grid item xs={12} md={4} justifyItems={'center'} sx={{ mt: { md: 0, sm: 0, xs: -8 } }}>
                    <Card
                        sx={{
                            backgroundColor: 'grey',
                            borderRadius: '8px',
                            height: { md: '400px', xs: '300px', sm: '500px' },
                            width: { md: '400px', xs: '300px', sm: '500px' },
                            borderLeftColor: 'gery',
                            boxShadow: '28px 28px 15px rgba(0, 0, 1, 3.8)', // Adds shadow to left and bottom
                        }}
                    >
                        <CardMedia
                            sx={{
                                height: { xs: 300, sm: 550, md: 400 },
                                width: { xs: 300, sm: 550, md: 450 },
                                maxWidth: '100%',
                            }}
                            component="img"
                            image={banner}
                            alt="Banner Image"
                        />
                    </Card>

                </Grid>

                {/* Mission Card */}
                <Grid item xs={12} md={7} id='vision-mission' sx={{ ml: { md: 3, sm: 0, xs: 0 }, mt: { md: 0, sm: 0, xs: -4 } }}>
                    {/* Mission Card */}
                    <Card
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            width: { xs: '280%', md: 600, sm: 600 }, // Adjust width for mobile 
                            mt: 4,
                            ml: { xs: -12, sm: -10, md: 0 } // Left alignment on mobile
                        }}
                    >
                        <CardContent>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'purple' }}>
                                Mission
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                To provide top-notch, industry-relevant software development courses that offer real-world experience, encourage problem-solving attitudes, and prepare students for success in the fast-paced tech sector.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Vision Card */}
                    <Card
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            width: { xs: '280%', md: 600, sm: 600 }, // Adjust width for mobile 
                            mt: 4,
                            ml: { xs: -12, sm: 2, md: 10 } // Left alignment on mobile
                        }}
                    >
                        <CardContent>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'purple' }}>
                                Vision
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                To become a premier provider of high-quality software development training that equips individuals with cutting-edge tools and innovative ideas to advance the digital world.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

            {/* 'programs-certificates' */}
            <Grid
                container
                sx={{
                    // backgroundColor: 'white',

                    minHeight: '50vh',
                    py: 4,
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={12} md={12} id={'programs-certificates'}>
                    <Typography variant="h3" sx={{ fontWeight: 600, mt: 1, textAlign: 'center', color: '#4b87a6',  fontSize: { xs: '30px', sm: '40px', md: '40px' }}}>
                        Programs and Certificates
                    </Typography>

                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    spacing={2} // Adds space between grid items
                    sx={{ padding: '40px 20px', mt: 2 }}

                >
                    {post.slice(0, 10).map((item, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}> {/* Adjusted for responsive design */}
                            <Card sx={{ width: '100%', backgroundColor: 'white', padding: '0px 5px' }}>
                                <CardContent>
                                    <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 800, mt: 4, textAlign: 'center', color: 'black', }}>
                                        {item.title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

{/* Career and Growth */}

{/* <Grid container sx={{padding:'10px 10px', mt:-4}}>
                <Grid item xs={12} md={12} id={'career-growth'}>
                <Typography
                        variant='h3'
                        sx={{
                            textAlign: { md: 'center', xs: 'center' },
                            fontWeight: 600,
                            color: '#4b87a6',
                            fontSize: { xs: '30px', sm: '40px', md: '40px' },
                        }}
                    >
                        Career and Growth
                    </Typography>
                    <Typography variant='h4'>
<span> Key Phases in Software Development</span>
                    </Typography>
                    <Grid>

                    </Grid>

                </Grid>


            </Grid> */}




            {/* Robotic frameworks */}

            <Grid
                container
                // spacing={1} 
                id='programming-robotics'
                sx={{ backgroundColor: 'white', padding: '30px 80px', flexDirection: { xs: 'column', md: 'row' }, }}
            >
                {/* Title Section */}
                <Grid item xs={12} sx={{ padding: '20px 20px' }}>
                    <Typography
                        variant='h3'
                        sx={{
                            textAlign: { md: 'center', xs: 'left' },
                            fontWeight: 600,
                            color: '#4b87a6',
                            fontSize: { xs: '30px', sm: '40px', md: '40px' },
                        }}
                    >
                        Robotics Frameworks
                    </Typography>
                </Grid>

                {/* Slider Section */}
                <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    md={8}
                    sx={{
                        padding: '10px',
                        mt: { xs: 3, md: 0 },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Stack in column on xs and row on sm and up
                        // flexWrap: 'wrap', // Allow wrapping of items
                        justifyContent: 'space-between'
                    }}
                >
                    {robot.map((post, index) => (
                        <Grid key={index} item xs={12} sm={6} md={6}>
                            <Card
                                sx={{
                                    // marginBottom: 2, 
                                    width: '80%',  // Take full width of the Grid item
                                    height: '60%',
                                    borderRadius: 2,
                                    backgroundColor: '#f7f7f5',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CardContent sx={{ flexGrow: 1, }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontFamily: 'sans-serif', fontWeight: 600, height: 50, backgroundColor: '#b0a78d', width: 600, alignContent: 'center', textAlign: 'center', ml: -21, mt: -2, mr: 5 }}
                                    >
                                        {post.title}
                                    </Typography>
                                    <Divider />

                                    <Typography sx={{ height: 120, mt: 2 }}>  {post.description}</Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>


                {/* Banner Image Section */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Card
                        sx={{
                            marginBottom: '70px',
                            width: { xs: '90%', md: '100%' },
                            height: { xs: '300px', md: '80%' },
                            borderRadius: '8px',
                            ml: { md: 2, xs: 0 }
                        }}
                    >
                        <CardMedia
                            sx={{
                                height: '100%',
                                width: '100%',
                            }}
                            component="img"
                            image={banner}
                            alt="Banner Image"
                        />
                    </Card>
                </Grid>
            </Grid>



        </Box>

    );
};

export default Comp2;
