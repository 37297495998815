import { Grid, Typography, Box, Divider } from '@mui/material';
import React from 'react';
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GppGoodIcon from '@mui/icons-material/GppGood';

const coreValues = [
    { icon: <SensorOccupiedOutlinedIcon fontSize="large"  />, title: 'Client-Centric Approach', description: 'We understand unique needs and Customized solutions' },
    { icon: <TipsAndUpdatesOutlinedIcon fontSize="large" />, title: 'Innovation & Creativity', description: 'Encouraging creative solutions and staying innovative strategies' },
    { icon: <HandshakeOutlinedIcon fontSize="large" />, title: 'Transparency & Integrity', description: 'open communication,honest insights and clear reporting' },
    { icon: <TrackChangesIcon fontSize="large" />, title: 'Results-Driven Strategies', description: 'delivering measurable outcomes maximize ROI and business growth' },
    { icon: <Diversity3Icon fontSize="large" />, title: 'Collaboration & Teamwork', description: 'working closely with clients and as a cohesive team to achieve goals' },
    { icon: <GppGoodIcon fontSize="large" />, title: ' Customer Satisfaction', description: 'aiming for long-term partnerships' },
];

const Core = () => {
    return (
        <Grid container sx={{ backgroundColor: '#f2f5f4', padding: 4 }}>
            <Grid item xs={12} sx={{ textAlign: 'center', color: '#907394' }}>
                <Typography
                    variant="h2"
                    gutterBottom
                    sx={{ fontWeight: 600, color: '#907394', mb: 5, fontSize: '40px' }}
                >
                    Our Core Values
                </Typography>
            </Grid>

            <Grid container spacing={5} sx={{ justifyContent: 'center' }} padding={8} >
                {coreValues.map((value, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Box sx={{ marginRight: '15px', color: '#254f6d', mt: 1 , }}>
                                {value.icon}
                            </Box>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    backgroundColor: '#907394',
                                    width: '2px',
                                    margin: '0 15px',
                                    height: '70px',
                                }}
                            />
                            <Box>
                                <Typography variant="body1" sx={{ fontWeight: 600 , fontSize:'18px'}}>
                                    {value.title}
                                </Typography>
                                <Typography variant="body2" sx={{ marginTop: '10px', fontSize: { xs: '15px', md: '16px' } }}>
                                    {value.description}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default Core;
