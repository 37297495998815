import React from 'react'
import About1 from '../aboutus/About1';
import Header1 from '../Header1';
import Footer from '../Footer';
import About2 from '../aboutus/About2';
import About3 from '../aboutus/About3';
import Aboutgrid from '../aboutus/Aboutgrid'
import Core from '../aboutus/Core';

const Allabout = () => {
  return (
    <div>
    <Header1/>
    <Aboutgrid/>
    {/* <About1/>   */}
    {/* <About3/> */}
    <Core/>
    <About2/>
    <Footer/>
    </div>
  )
}

export default Allabout
