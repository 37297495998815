import React, { useState } from 'react';
import { Typography, Grid, Box, Button, Card, CardContent } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'; // for animation
import newarticlebackgroundimae from '../../../assets/newarticlesbackgroundimae.png'

const About2 = () => {
    const [showMore, setShowMore] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const cardData = [
        {
            number: '01',
            title: 'Our Vision',
            description: 'We understand how important trust is in this sector. This acknowledgment motivates us to provide our brands with every possible and creative kind of support.',
        },
        {
            number: '02',
            title: 'Our Mission',
            description: 'We provide our brands with every possible and creative support, guaranteeing that their needs are satisfied with unflinching faith in our talents.',
        },
        {
            number: '03',
            title: 'Our Values ',
            description: 'We place the utmost importance on our clients and value their demands.',
        },
    ];

    // Intersection Observer Hooks for visibility tracking (optional)
    const { ref: cardOneRef, inView: cardOneInView } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: cardTwoRef, inView: cardTwoInView } = useInView({ triggerOnce: true, threshold: 0.4 });
    const { ref: cardThreeRef, inView: cardThreeInView } = useInView({ triggerOnce: true, threshold: 0.6 });

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={0}  padding={'20px 20px'} position={'relative'} sx={{backgroundImage: `url(${newarticlebackgroundimae})`,}}>
            <Grid item xs={12} sm={6} md={4}>
                <Box
                    sx={{
                        padding: { md: '60px', xs: '20px' },
                        // backgroundColor: '#edf7f1',
                        textAlign: 'left',
                    }}
                >
                    <Typography variant="h6" sx={{ color: '#4b87a6', fontWeight: 'bold', marginBottom: '20px' }}>
                        These 3 commitments are Core
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', marginBottom: '30px', width: { md: '400px' }, fontSize: { md: '40px', xs: '30px' }, color:'#907394'}}>
                        Who We Are ??
                    </Typography>

                    {/* Static list of commitments */}
                    <Box>
                        {[
                            { number: '01', text: 'In our organization, we understand how important trust is in this sector.' },
                            { number: '02', text: 'We are committed to giving our full attention to our clients\' needs.' },
                            { number: '03', text: 'We place the utmost importance on our clients and value their demands.' },
                            { number: '04', text: 'We design, create, and carry out strategies with a seasoned team.' },
                        ].map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', mt: 2 }}>
                                <Typography variant="h5" sx={{ color: '#4b87a6', fontWeight: 'bold', marginRight: '10px' }}>
                                    {item.number}
                                </Typography>
                                <Typography variant="body1">{item.text}</Typography>
                            </Box>
                        ))}
                    </Box>

                    {showMore && (
                        <Box sx={{ marginTop: '20px' }}>
                            <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px', textAlign: 'justify' }}>
                                With a focus on digital marketing we empower our clients to reach their goals and enhance their online visibility. Our team of knowledgeable professionals uses state-of-the-art technologies to boost digital presence and propel business growth.
                            </Typography>
                        </Box>
                    )}
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: '#00a189',
                            color: '#00a189',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            marginTop: '20px',
                            '&:hover': { backgroundColor: '#00a189', color: '#fff' },
                        }}
                        onClick={toggleShowMore}
                    >
                        {showMore ? 'Read Less' : 'Read More'}
                    </Button>
                </Box>
            </Grid>

            {/* Cards with staggered animation but fixed positions */}
            <Grid item xs={12} md={8}>
                <Grid container sx={{ flexDirection: isMobile ? 'column' : 'row' }} spacing={4}>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <motion.div
                            ref={cardOneRef}
                            initial={{ opacity: 0, y: 50 }}
                            animate={cardOneInView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.5, delay: 0 }} // First card appears immediately
                        >
                            <Card
                                sx={{
                                    borderRadius: '30px',
                                    margin: '10px',
                                    textAlign: 'center',
                                    padding: 2,
                                    boxShadow: 3,
                                    marginTop: '90px',
                                    background: 'linear-gradient(45deg, #1e2b31, #2b4048, #3f616b, #4a727d)',
                                    color: '#fff',
                                    height: '50%',
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            fontSize: { md: '40px', xs: '30px' },
                                            color: '#67b7c2',
                                        }}
                                    >
                                        {cardData[0].number} {cardData[0].title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px', textAlign: 'justify' }}>
                                        {cardData[0].description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container sx={{ flexDirection: 'column' }} spacing={2}>
                            <Grid item>
                                <motion.div
                                    ref={cardTwoRef}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={cardTwoInView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 0.5, delay: 0.2 }} // Second card appears with a delay
                                >
                                    <Card
                                        sx={{
                                            borderRadius: '30px',
                                            margin: '10px',
                                            textAlign: 'center',
                                            padding: 2,
                                            boxShadow: 3,
                                            backgroundColor: '#ebebeb',
                                            color: 'black',
                                            height: '100%',
                                            width:'80%'

                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',                                                  
                                                    width: { md: '400px' },
                                                    fontSize: { md: '40px', xs: '30px' },
                                                    color: '#3f616b',
                                                    
                                                }}
                                            >
                                                {cardData[1].number} {cardData[1].title}
                                            </Typography>
                                            <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px', textAlign: 'justify' }}>
                                                {cardData[1].description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </motion.div>
                            </Grid>

                            <Grid item>
                                <motion.div
                                    ref={cardThreeRef}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={cardThreeInView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 0.5, delay: 0.4 }} // Third card appears with a delay
                                >
                                    <Card
                                        sx={{
                                            borderRadius: '30px',
                                            margin: '10px',
                                            textAlign: 'center',
                                            padding: 2,
                                            boxShadow: 3,
                                            backgroundColor: '#ebebeb',
                                            color: 'black',
                                            height: '100%',
                                            width:'80%'

                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    width: { md: '400px' },
                                                    fontSize: { md: '40px', xs: '30px' },
                                                    color: '#3f616b',
                                                    textAlign: 'center', 
                                                    
                                                    
                                                }}
                                            >
                                                {cardData[2].number} {cardData[2].title}
                                            </Typography>
                                            <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px', textAlign: 'justify' ,mb:3}}>
                                                {cardData[2].description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default About2;
