import React, { useRef, useEffect, useState, } from 'react';
import { Typography, Grid, useMediaQuery, Container } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Canvas } from '@react-three/fiber';
import { Line } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

// Component to create fading, curved lines with shadow based on mouse movement
function FadingLines() {
  const [lines, setLines] = useState([]);
  const lastMousePosition = useRef([0, 0]);
  const timeoutRef = useRef(null);

  // Number of lines to create at each mouse position
  const linesPerMove = 5;

  // Update lines on mouse move
  const handleMouseMove = (event) => {
    const mouseX = (event.clientX / window.innerWidth) * 5 - 5;
    const mouseY = -(event.clientY / window.innerHeight) * 10 + 5;

    // Generate multiple curved lines with shadows
    const newLines = Array.from({ length: linesPerMove }, (_, i) => {
      const angle = (i / linesPerMove) * Math.PI * 4; // Full circular spread
      const offsetX = 3 * Math.cos(angle);
      const offsetY = 3 * Math.sin(angle);

      // Create start and end points
      const start = new THREE.Vector3(lastMousePosition.current[0], lastMousePosition.current[1], 0);
      const end = new THREE.Vector3(mouseX, mouseY, 0);

      // Control points to create a curve
      const controlPoint1 = new THREE.Vector3(
        (lastMousePosition.current[0] + mouseX) / 2 + offsetX,
        (lastMousePosition.current[1] + mouseY) / 2 + offsetY,
        0.5
      );

      const controlPoint2 = new THREE.Vector3(
        (lastMousePosition.current[0] + mouseX) / 2 - offsetX,
        (lastMousePosition.current[1] + mouseY) / 2 - offsetY,
        -1.5
      );

      const curve = new THREE.CubicBezierCurve3(start, controlPoint1, controlPoint2, end);
      const points = curve.getPoints(10);

      // Filter points for smoother curvature
      const spacedPoints = points.filter((_, index) => index % 1 === 0);

      return {
        points: spacedPoints,
        opacity: 0.5, // Main line initial opacity
        shadowOpacity: 0.1, // Shadow line initial opacity
      };
    });

    // Update last mouse position
    lastMousePosition.current = [mouseX, mouseY];

    // Add the new lines to the state
    setLines((prevLines) => [...prevLines, ...newLines]);

    // Clear any previous fade-out timeout
    clearTimeout(timeoutRef.current);

    // Start fade-out after mouse stops moving
    timeoutRef.current = setTimeout(() => {
      fadeOutLines();
    }, 1);
  };

  // Fade out lines over time by reducing their opacity
  const fadeOutLines = () => {
    setLines((prevLines) =>
      prevLines
        .map((line) => ({
          ...line,
          opacity: line.opacity - 0.01, // Slower fade for main line
          shadowOpacity: line.shadowOpacity - 0.005, // Slower fade for shadow line
        }))
        .filter((line) => line.opacity > 0 || line.shadowOpacity > 0) // Keep lines until fully faded
    );
  };

  // Add an event listener for mouse movement
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Continuously fade lines out in the animation loop
  useFrame(() => {
    fadeOutLines();
  });

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {/* Main line */}
          <Line
            points={line.points}
            color="purple"
            opacity={line.opacity}
            transparent
            lineWidth={1}
          />
          {/* Shadow line with offset */}
          
        </React.Fragment>
      ))}
    </>
  );
}


const Aboutdigital = () => {
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:1024px)');

    const [completedProjects, setCompletedProjects] = useState(10);
    const [satisfiedCustomers, setSatisfiedCustomers] = useState(10);
    const [expertEmployees, setExpertEmployees] = useState(10);
    const [awardsWon, setAwardsWon] = useState(10);

    useEffect(() => {
        if (completedProjects < 1000) {
            const interval = setInterval(() => {
                setCompletedProjects((prev) => Math.min(prev + 10, 1000));
            }, 30);
            return () => clearInterval(interval);
        }
    }, [completedProjects]);

    useEffect(() => {
        if (satisfiedCustomers < 600) {
            const interval = setInterval(() => {
                setSatisfiedCustomers((prev) => Math.min(prev + 10, 600));
            }, 30);
            return () => clearInterval(interval);
        }
    }, [satisfiedCustomers]);

    useEffect(() => {
        if (expertEmployees < 50) {
            const interval = setInterval(() => {
                setExpertEmployees((prev) => Math.min(prev + 1, 50));
            }, 100);
            return () => clearInterval(interval);
        }
    }, [expertEmployees]);

    useEffect(() => {
        if (awardsWon < 20) {
            const interval = setInterval(() => {
                setAwardsWon((prev) => Math.min(prev + 1, 20));
            }, 100);
            return () => clearInterval(interval);
        }
    }, [awardsWon]);

    const stats = [
        {
            icon: <CheckCircleOutlineIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#00BFFF' }} />,
            count: completedProjects,
            label: 'Completed Projects',
        },
        {
            icon: <GroupIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#32CD32' }} />,
            count: satisfiedCustomers,
            label: 'Satisfied Customers',
        },
        {
            icon: <BusinessCenterIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#FFA500' }} />,
            count: expertEmployees,
            label: 'Expert Employees',
        },
        {
            icon: <EmojiEventsIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#FFD700' }} />,
            count: awardsWon,
            label: 'Awards Won',
        },
    ];

    return (
        <Container 
            maxWidth={false} 
            sx={{ 
                backgroundColor: '#051836', 
                padding: { xs: '10px 0', md: '100px 0' },
                width: '100vw', 
                marginLeft: 0, 
                marginRight: 0, 
                minHeight:isTablet? '10vh' :'60vh', 
                position: 'relative', // Make the container relative to hold absolute canvas
                zIndex: 1, // Keep content above canvas
            }}
        >
            <Canvas
                camera={{ position: [0, 0, 5], fov: 20 }}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1, // Canvas goes behind the content
                }}
            >
                <ambientLight intensity={3.5} />
                <FadingLines />
            </Canvas>

            <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" sx={{
                mt: { xs: 4, sm: 6, md: 3 }, 
                mb: { xs: 4, sm: 6, md: 5 },
            }}>
                {stats.map((stat, index) => (
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        md={3} 
                        sx={{
                            textAlign: 'center', 
                            padding: { xs: '5px 0', md: '10px 0' },
                            fontSize: isTablet ? '1.5rem' : 'initial',
                            transform: isTablet ? 'scale(1.05)' : 'initial',
                        }} 
                        key={index}
                    >
                        {stat.icon}
                        <Typography 
                            variant="h4" 
                            sx={{ 
                                fontWeight: 'bold', 
                                color: '#fff', 
                                fontSize: { xs: '1.5rem', md: '2rem' },
                            }}
                        >
                            {stat.count}+
                        </Typography>
                        <Typography 
                            variant="subtitle1" 
                            sx={{ 
                                color: '#fff', 
                                fontSize: { xs: '1rem', md: '1.5rem' },
                            }}
                        >
                            {stat.label}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Aboutdigital;
