import { Grid , Fab, useScrollTrigger} from '@mui/material';
import React from 'react'
import Headerw from './Headerw'
import Header1 from '../../Page/Header1'
import Footer from '../../Page/Footer'
import Development from './Development'
import Featured from './Featured'
import Company from './Company'
import Comp2 from './Frontend.js/Com2'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Fade from '@mui/material/Fade';
import Combo12 from './Frontend.js/Combo12'

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <div 
        onClick={handleClick} 
        role="presentation" 
        style={{ 
          position: 'fixed', 
          bottom: 16, 
          right: 16, 
          zIndex: 1000 // Ensure it stays on top
        }}
      >
        {children}
      </div>
    </Fade>
  );
}


const Allweb = (props) => {
  return (
    <Grid sx={{ overflowX: 'hidden', }}>
      <div id="back-to-top-anchor" />
        <Header1/>
      {/* <Headerw/> */}
      <Comp2/>
      {/* <Combo12/> */}
      {/* <Development/> */}
      {/* <Featured/> */}
      {/* <Company/> */}
      <ScrollTop {...props}>
        <Fab size='large' aria-label='scroll back to top'>
          <ArrowUpwardIcon sx={{ fontSize: '30px' }} />
        </Fab>
      </ScrollTop>
      <Footer/>
    </Grid>
  )
}

export default Allweb
