import { Grid, Typography,Grow } from '@mui/material'
import React from 'react'
import gallary2 from '../../assets/Gallery/Gallary2.png';
import gallary1 from '../../assets/Gallery/Gallary1.png';
import gallary3 from '../../assets/Gallery/Gallary3.png';
import gallary4 from '../../assets/Gallery/IMG_7690.jpg';
import gallary5 from '../../assets/Gallery/IMG_7682 (3).jpg';
import gallary6 from '../../assets/Gallery/IMG_7704.jpg';
import gallary7 from '../../assets/Gallery/IMG_7680.jpg';
import styled from 'styled-components';
import Header from './Header1';
import Footer from './Footer';

const images = [gallary5, gallary1, gallary2, gallary4, gallary6,gallary3, gallary7]

const Gallery = () => {
  const StyledSpan = styled.span`
&::before {
  
}
text-decoration: underline;
color: 'purple';
`;
  return (
    <Grid>
      <Header />
      <Grid container sx={{ padding: '40px 80px' }} spacing={1}>
      
        <Grid item xs={12} textAlign="center" mt={2} mb={4}>
          <Typography variant="h2" fontSize="40px" fontWeight={600} color='#004e6a'>
            <StyledSpan>A Gallery of Training Highlights </StyledSpan>
          </Typography>
        </Grid>
        {images.map((imgSrc, index) => (
          <Grow in={true} timeout={(index + 1) * 1000} >
          <Grid item xs={12} sm={6} md={4} lg={4} key={index} sx={{ mt: 2, mb: 3 }}  >

            <img
              src={imgSrc}
              alt={"GetMoreDitital-Logo"}
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>
          </Grow>
        ))}

      </Grid>
      <Footer />
    </Grid>
  )
}

export default Gallery